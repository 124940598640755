/*eslint react-hooks/exhaustive-deps: off */
import React from "react";

import "./ChartControl.css";

interface ControlProps {
    items: Array<{ id: string, onChange: Function }>;
}

export default function ChartControl({ items }: ControlProps) {
    if (!items || !items.length) {
        return <></>
    }

    const localHandler = (event: any) => {
        const element = event.target;
        element.parentElement.querySelector(".active")?.classList?.remove("active");
        element.classList.add("active");

        const changeInfo = {
            clickEvent: event,
            id: element.getAttribute("data-id"),
            value: element.value || element.getAttribute("data-id"),
            data: {...element.dataset},
            selectedIndex: element.selectedIndex,
            index: parseInt(element.getAttribute("data-index")),
        };

        items[changeInfo.index].onChange(changeInfo);
    };

    const renderSelectOption = (option: any, index: number) => (
        <option key={option.key || option.id} data-selectindex={index} value={option.id}>{option.label}</option>
    );

    const renderSelectOptionGroup = (optionGroup: any, index: number) => (
        <optgroup key={optionGroup.label + index} label={optionGroup.label}>
            {optionGroup.options.map((option: any, index: number) => renderSelectOption(option, index))}
        </optgroup>
    );

    const renderSelect = (item: any, index: number) => (
        <select key={item.id} data-key={item.id} data-id={item.id} data-index={index} defaultValue={item.defaultValue} onChange={localHandler}>
            {item.options.map((option: any, index: number) => option.options ? renderSelectOptionGroup(option, index) : renderSelectOption(option, index))}
        </select>
    );

    const renderLink = (item: any, index: number) => (
        <div key={item.id} data-key={item.id} data-id={item.id} className={item.isActive ? "active" : ""} data-index={index} onClick={localHandler}>{item.label || item.id}</div>
    );

    return <div className="controls">
        {items.map((item: any, index: number) => item.options ? renderSelect(item, index) : renderLink(item, index))}
    </div>;
}
