import React from 'react';
import { ScrollRestoration, Outlet, useParams } from "react-router-dom";
import { useDarkMode, useEffectOnce } from 'usehooks-ts';

import AppTitle from 'app/AppTitle';
import Navigation from "app/Navigation";
import useVisitedKeyStorage from 'social/useVisitedKeyStorage';
import useDepotData from 'depot/useDepotData';

export default function AppLayout() {
    const { isDarkMode, disable } = useDarkMode();
    useEffectOnce(disable/*darkmode*/);

    // next two lines are crucial ! Here loading and saving happens
    const { peerfolioKey } = useParams();
    useDepotData( peerfolioKey );
    useVisitedKeyStorage( );

    return (
        <div className={"App" + (isDarkMode ? " darkModeDetectionEnabled" : "")}>
             <header>
                <div className="nav__logo">Peerfol.io</div>
                <AppTitle />
                <Navigation />
            </header>
        
            <main>
                <Outlet />
                <ScrollRestoration />
            </main>
        
            <footer>
                <div>
                    <a href="https://peerfol.io" target="_blank" className="creditsStyles" rel="noreferrer">peerfol.io</a>
                    <span> | Show ♥ by recommending</span>
                </div>
            </footer>
        </div>
    )
}
