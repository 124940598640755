/*eslint react-hooks/exhaustive-deps: off */
import React, { useState, useRef } from "react";

import { formatNumber, getPnlTable } from 'common/Utils';
import ChartControl from 'depot/components/ChartControl';
import { BarChart } from 'depot/components/Chart';
import useDepotData from 'depot/useDepotData';

export default function PnlChart() {
    console.log("PnlChart");

    const depotData = useDepotData();
    const [sortColumn, setSortColumn]: any = useState(0);
    const updateSortColumn = (clickInfo: any) => setSortColumn(clickInfo.index);
    const chart = useRef(null);

    refreshChart(chart, depotData, sortColumn);

    return (depotData?.stocks.length ?
        <div>
            <ChartControl items={getControlItems(updateSortColumn)} />
            <BarChart ref={chart} />
        </div>
        :
        <></>
    )
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function refreshChart(chart: any, depotData: any, sortColumn: number) {
    if (chart?.draw && depotData?.stocks?.length) {
        const dataTable = getDataTable(depotData, sortColumn);
        chart.draw(dataTable, getChartOptions(dataTable));
    }
}

function getControlItems(controlItemClick: any) {
    return [
        { id: "total", isActive: true, onChange: controlItemClick, },
        { id: "abs", onChange: controlItemClick, },
        { id: "rel", onChange: controlItemClick, },
    ];
}

function getDataTable(depotData: any, sortColumn: number) {
    const dataTable = new window.google.visualization.DataTable({
        cols: [
            { type: "string", label: "Name", },
            { type: "string", role: "tooltip", p: { "html": true }, },
            { type: "number", label: "Gray", },
            { type: "number", label: "Green", },
            { type: "string", role: "style", },
            { type: "string", role: "annotation", },
            { type: "number", label: "Red", },
            { type: "number", label: "absSort", },
            { type: "number", label: "relSort", },
            { type: "number", label: "totalSort", },
        ]
    });

    if (!depotData?.stocks) {
        return dataTable;
    }

    dataTable.addRows(
        depotData.stocks
            .filter((stock: any) => stock.count > 0)
            .map((stock: any) => [
                /*label*/       stock.name,
                /*tooltip*/     getPnlTable({
                                    headline: stock.name.substr(0, 50),
                                    valueCurrency: depotData.currency,
                                    valueStart: stock.buyValue,
                                    valueEnd: stock.value,
                                    priceCurrency: stock.currency,
                                    priceStart: stock.buyPrice,
                                    priceEnd: stock.price,
                                }),
                /*gray*/        Math.min(stock.value, stock.buyValue),
                /*green*/       Math.max(0, stock.valueDiffAbs),
                /*style*/       "color:" + (stock.valueDiffPer > 1 ? "#007700" : (stock.valueDiffPer < -1 ? "#FF0000" : "#777777")),
                /*annotation*/  formatNumber(stock.valueDiffPer, "%", undefined, true),
                /*red*/         Math.max(0, stock.valueDiffAbs * -1),
                /*totalSort*/   stock.value,
                /*absSort*/     stock.valueDiffAbs,
                /*relSort*/     stock.valueDiffPer,
            ])
    );

    const dataView = new window.google.visualization.DataView(dataTable);
    dataView.setRows(dataTable.getSortedRows({ column: sortColumn + 7 }));
    dataView.hideColumns([7, 8, 9]); // remove sort columns
    return dataView;
}

function getChartOptions(dataTable: any) {
    return {
        bar: { groupWidth: "80%" },
        bars: "horizontal",
        legend: { position: "none" },
        isStacked: true,
        focusTarget: "category",
        theme: "material",
        width: "100%",
        height: (dataTable.getNumberOfRows() * 20 + 80),
        backgroundColor: "transparent",
        hAxis: {
            format: "short",
        },
        vAxis: {
            maxTextLines: 1,
        },
        chartArea: {
            top: 10,
            bottom: 25,
            left: 140,
            right: 25,
        },
        tooltip: { isHtml: true },
        annotations: {
            datum: {
                stem: {
                    length: 4,
                    color: "transparent",
                },
            },
            style: "point",
            textStyle: {
                fontSize: 10,
                bold: true,
                color: "#111",
            },
        },
        series: [
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#777777",    // gray area
            },
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#006600",    // green area
            },
            {
                targetAxisIndex: 0,  // red area
                lineWidth: 0,
                dataOpacity: 0.1,
                color: "#ff7777",
            },
        ],
    };
}
