/*eslint react-hooks/exhaustive-deps: off */
import React from "react";

import './PopUp.css';

// TODO: replace with standard Modal lib
export default function PopUp({ children, closeFn }: any) {
    const closeHandler = (event: any) => {
        if (event.target.classList.contains("modal")
            || event.target.classList.contains("modal__close")) {
            closeFn();
        }
    }

    return <div className="modal" onClick={closeHandler}>
        <div className="modal__content">
            {children}
            <div className="modal__close">&times;</div>
        </div>
    </div>
}
