import { useState } from 'react';
import { useEffectOnce } from 'usehooks-ts'

import useDepotData, { getApiUrl } from 'depot/useDepotData';

export default function useQuotesData() {
    const depotData = useDepotData();

    const [quotesData, setQuotesData]: any = useState(null);

    useEffectOnce(() => {
        loadDataOnce(depotData).then((data: any) => {
            setQuotesData(data);
        });
    });

    return quotesData;
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
async function loadDataOnce(depotData: any) {
    const apiUrl = getApiUrl("quotes", depotData.key, { cacheInvalidator: /*2h=*/ ~~(Date.now() / 1000 / 60 / 120) });

    return fetch(apiUrl, { cache: "force-cache" }).then(response => response.json())
        .then(data => {
            data.depotData = { ...depotData };
            data.getFilteredDataTable = getFilteredDataTable;
            return data;
        });
};

function getFilteredDataTable(quotesData: any, filterDuration: number) {
    const dataTable = getDataTable(quotesData, filterDuration);
    const dataView = changeResolution(dataTable, filterDuration);
    return dataView;
}

function getDataTable(quotesData: any, filterDuration: number) {
    const dataTable: any = new window.google.visualization.DataTable({
        cols: [{ type: "date", label: "Time" }, { type: "number" }]
    });

    if (!quotesData) {
        return dataTable;
    }

    const minDate: any = (new Date()).setDate((new Date()).getDate() - filterDuration);
    const stocks: any = quotesData.depotData.stocks.filter((x: any) => x.count > 0);
    const stockCount = Math.min(50, stocks.length);

    /* not clear if needed:
    stocks = stocks
        .sort(((a: any, b: any) => (a.value > b.value) ? 1 : -1))
        .slice(stockCount * -1)
        .reverse();
    */

    const columns: any = [];
    const rows: any = {};
  
    if (quotesData.stocks && Object.keys(quotesData.stocks).length) {
        dataTable.removeColumn(1);

        Object.values(stocks)
            .filter((stockInfo: any) => quotesData.stocks[stockInfo.id].quotes)
            .forEach((stockInfo: any, index: number) => {
                Object.entries(quotesData.stocks[stockInfo.id].quotes)
                    .forEach(([dateString, price]: any) => {
                        const date = new Date(dateString);

                        // TODO: find deeper reason for this hack
                        // was required for roberts depot > tesla > "2019-07-08021-05-27"
                        if (dateString.length !== 10) {
                            return;
                        }

                        if (date < minDate || date < stockInfo.buyDate || !price) {
                            return;
                        }

                        if (!rows[dateString]) {
                            rows[dateString] = new Array(stockCount + 1).fill(null);
                            rows[dateString][0] = date;
                        }

                        if (!columns[index]) {
                            columns[index] = {
                                startValue: price,
                                label: stockInfo.name,
                                columnId: index,
                            };
                        }
                        // if (!columns[i].startValue) {
                        //  columns[i].startValue = price;
                        // }
                        columns[index].lastDate = dateString;
                        columns[index].performance = price / columns[index].startValue - 1;
                        rows[dateString][1 + index] = columns[index].performance;
                    });

            });
    }

    if (Object.keys(rows).length) {
        dataTable.addRows(getTopAndButton5(dataTable, rows, columns));

        // format to percent
        const formatter = new window.google.visualization.NumberFormat({ pattern: '#,###%', negativeColor: '#FF0000' });
        for (let i = 1; i < dataTable.getNumberOfColumns(); i += 2) {
            formatter.format(dataTable, i);
        }
    }

    return dataTable;
}

function changeResolution(dataTable: any, filterDuration: number) {
    const unixTimeWeekOffset: any = 24 * 60 * 60 * 4; // start on MONDAY, 5Jan1970
    const respolutions: any = { 30: 24, 90: 24, 365: (2 * 24), 1825: (5 * 24) };
    const resolutionInHours: any = respolutions[filterDuration] || 24;

    const filter = {
        column: 0,
        test: (value: any, rowId: any, columnId: any, datatable: any) => {
            if (!rowId || rowId > datatable.getNumberOfRows() - 7) {
                return true; // keep first and last row
            }
            const nextValue = datatable.getValue(rowId + 1, columnId);
            const bucketThisRow = (value.getTime() / 1000 - unixTimeWeekOffset) / (60 * 60 * resolutionInHours);
            const bucketNextRow = (nextValue.getTime() / 1000 - unixTimeWeekOffset) / (60 * 60 * resolutionInHours);
            return Math.floor(bucketThisRow) !== Math.floor(bucketNextRow);
        },
    };

    const dataView = new window.google.visualization.DataView(dataTable);
    const visibleRowIds = dataTable.getFilteredRows([filter]);
    dataView.setRows(visibleRowIds);

    return dataView;
}

function getTopAndButton5(dataTable: any, rows: any, columns: any) {
    // sort rows by date
    rows = Object.values(rows).sort((a: any, b: any) => {
        return a[0] > b[0] ? 1 : -1;
    });

    // sort columns by perf
    columns.sort((a: any, b: any) => {
        return a.performance < b.performance ? 1 : -1;
    });

    // only leave top and bottom 5 columns
    const columnCount = columns.length;
    if (columns.length > 10) {
        columns.splice(5, columns.length - 10);
    }

    // add actual columns to dataTable
    columns.forEach((column: any, index: number) => {
        const num = (columnCount > 10 && index > 4 ? columnCount - (9 - index) : index + 1);
        dataTable.addColumn('number', num + ") " + column.label);
        dataTable.addColumn({ type: 'string', role: 'annotation' });
    });

    // transform rows according to column performance, and fill missing data
    return rows.map((row: any, rowIndex: number) => {
        const transformedRow = [row[0]]; // date

        columns.forEach((col: any, colIndex: number) => {
            const rowDate = transformedRow[0].toISOString().substring(0, 10);

            var value = rows[rowIndex][col.columnId + 1];
            if (!value && rowDate < col.lastDate) {
                value = columns[colIndex].lastValue;
            }

            const annotation = (col.lastDate === rowDate ? col.label.substring(0, 15) : null);

            transformedRow.push(value, annotation);
            columns[colIndex].lastValue = value;
        });
        return transformedRow;
    });
}
