/*eslint react-hooks/exhaustive-deps: off */
import React, { useState, useRef } from "react";
import { useDarkMode } from 'usehooks-ts';

import useHistoryData, { getFilteredDataTable } from 'depot/useHistroyData';
import { sanitizeName } from 'depot/useDepotData';
import ChartControl from 'depot/components/ChartControl';
import { ComboChart } from 'depot/components/Chart';

export default function HistoryChart() {
    console.log("HistoryChart");

    // controls
    const [controlItems, setControlItems]: any = useState([]);
    const updateControlItems = (historyData: any) => {
        const updateFilterDuration = (clickInfo: any) => setFilterDuration(Number(clickInfo.value));
        const updateFilterStockId = (clickInfo: any) => {
            setFilterShowTrades(clickInfo.selectedIndex > 0);
            setFilterStockId(clickInfo.value);
        };
        const updateFilterType = (clickInfo: any) => setFilterType(clickInfo.value);

        setControlItems([
            getControlFilterDuration(historyData, filterDuration, updateFilterDuration),
            getControlFilterStockId(historyData, filterStockId, updateFilterStockId),
            getControlFilterType(filterType, updateFilterType),
        ]);
    };

    const { isDarkMode } = useDarkMode();
    const chart = useRef();
    const [filterDuration, setFilterDuration]: any = useState(30);
    const [filterStockId, setFilterStockId]: any = useState(0);
    const [filterType, setFilterType]: any = useState("abs");
    const [filterShowTrades, setFilterShowTrades]: any = useState(false);
    const historyData = useHistoryData(filterStockId);
    if (historyData?.rows) {
        if (controlItems?.length) {
            refreshChart(
                chart,
                getFilteredDataTable(historyData, filterDuration, filterType, (filterShowTrades || filterStockId > 0)),
                isDarkMode
            );
        } else {
            updateControlItems(historyData);
        }
    }

    return (
        <div>
            <ChartControl items={controlItems} />
            <ComboChart ref={chart} />
        </div>
    );
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function refreshChart(chart: any, dataTable: any, isDarkMode: boolean) {
    if (chart?.draw && dataTable?.getNumberOfRows()) {
        chart.draw(dataTable, getHistoryChartConfig(isDarkMode));
    }
}

function getControlFilterDuration(historyData: any, filterDuration: any, updateFilterDuration: any) {
    const filterDurationControlItems: any = {
        id: "filterDuration",
        defaultValue: filterDuration,
        onChange: updateFilterDuration,
        options: [
            { id: 7, label: "7d", },
            { id: 30, label: "1m", },
        ],
    };

    if (!historyData?.rows) {
        return filterDurationControlItems;
    }

    const dates: any = Object.keys(historyData.rows);
    if (dates) {
        const dateMin = new Date(dates[0]);
        const dateMax = new Date(dates.pop());
        const dayRange = Math.floor((dateMax.getTime() - dateMin.getTime()) / 1000 / 60 / 60 / 24);
        if (dayRange > 25) {
            // default is one month; but if enough data is available, switch to 3 months
            // filterDuration = 90;
            filterDurationControlItems.defaultValue = 90;
            filterDurationControlItems.options.push({ id: 90, label: "3m", });
        }
        if (dayRange > 85) {
            filterDurationControlItems.options.push({ id: 180, label: "6m", });
        }
        if (dayRange > 175) {
            filterDurationControlItems.options.push({ id: 365, label: "1y", });
        }

        const minYear = dateMin.getFullYear();
        const maxYear = dateMax.getFullYear();
        if (minYear !== maxYear) {
            filterDurationControlItems.options.push({ label: "———", options: [] });

            for (let y = minYear; y <= maxYear; y += 1) {
                filterDurationControlItems.options.push({ id: y, label: y, });
            }

            filterDurationControlItems.options.push({ id: 10000, label: "max", });
        }
    }

    return filterDurationControlItems;
}

function getControlFilterStockId(historyData: any, filterStockId: any, updateFilterStockId: any) {
    const filterStockControlItems: any = {
        id: "filterStockId",
        defaultValue: filterStockId,
        onChange: updateFilterStockId,
        options: [
            { id: 0, label: "All", },
        ],
    };

    if (!historyData?.stockFilterAvailable) {
        return filterStockControlItems;
    }

    if (Object.keys(historyData.transactions).length) {
        filterStockControlItems.options.push({ id: 0, key: "0-withalltrades", label: "All (incl Trades)", });
    }

    const stockOptionGroups: any = [
        { label: "Current", options: [] },
        { label: "Old", options: [] },
    ];

    const stocksEntries: any = Object.entries(historyData.stockFilterAvailable).sort((a: any, b: any) => a[1] > b[1] ? 1 : -1);
    for (const [stockId, name] of stocksEntries) {
        // TODO: add to backend the information if stock is current or not
        const isOldStock = (Math.random() < 0.5);
        stockOptionGroups[Number(isOldStock)].options.push({ id: stockId, label: sanitizeName(name || stockId), });
    }
    stockOptionGroups
        .filter((optGroup: any) => optGroup.options.length)
        .forEach((optGroup: any) => filterStockControlItems.options.push(optGroup));

    return filterStockControlItems;
}

function getControlFilterType(filterType: any, updateFilterType: any) {
    const filterTypeControlItems = {
        id: "filterType",
        defaultValue: filterType,
        onChange: updateFilterType,
        options: [
            { id: "abs", label: "Abs", },
            { id: "rel", label: "Rel", },
        ],
    };

    return filterTypeControlItems;
}

function getHistoryChartConfig(isDarkMode: boolean) {
    const historyChartConfig: any = {
        width: "100%",
        height: 400,
        hAxis: {
            textStyle: {
                fontSize: 12,
            }
        },
        backgroundColor: "transparent",
        animation: {
            duration: 600,
            easing: "inAndOut",
        },
        focusTarget: "category",
        chartArea: {
            top: 10,
            bottom: 40,
            left: 40,
            right: 40,
        },
        tooltip: {
            isHtml: true,
        },
        isStacked: "absolute",
        vAxes: [
            {
                format: "short",
                textStyle: { fontSize: 12, },
            },
            {
                gridlines: { count: 0 },
                format: "percent",
                textStyle: { fontSize: 12 },
            },
        ],
        annotations: {
            datum: {
                stem: {
                    length: 2,
                    color: "transparent",
                }
            },
            style: "point",
            textStyle: {
                fontSize: 8,
                color: "#000000",
                auraColor: "#FFFFFF",
            }
        },
        legend: "none",
        seriesType: "area",
        series: [
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#AAA",    // gray area
            },
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "black",    // buy points
                pointSize: 7,
            },
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#006600",    // green area
            },
            {
                targetAxisIndex: 0,
                lineWidth: 0,
                color: "#FFBBBB",    // red area
            },
            {
                targetAxisIndex: 0,
                type: "line",
                lineWidth: 1,
            },
            {
                targetAxisIndex: 1,   // right axis percentage
                type: "line",
                lineWidth: 2,
            },
        ],
    };

    if (isDarkMode) {
        historyChartConfig.hAxis.textStyle.color = "#DDD";
        historyChartConfig.vAxes[0].textStyle.color = "#DDD";
        historyChartConfig.vAxes[0].gridlines = { color: "#444" };
        historyChartConfig.vAxes[0].minorGridlines = { color: "#444" };

        historyChartConfig.vAxes[1].textStyle.color = "#DDD";
        historyChartConfig.vAxes[1].baselineColor = { color: "#AAA" };

        historyChartConfig.series[0].color = "#FFF";
        historyChartConfig.series[1].color = "#00FF00";
        historyChartConfig.series[2].color = "#FF0000";
        historyChartConfig.series[4].lineWidth = 2;
    }

    return historyChartConfig;
}
