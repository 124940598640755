import React from "react";
import { NavLink, useParams } from 'react-router-dom';

export default function Navigation() {
    const { peerfolioKey } = useParams();

    const getNavLinkClasses = ({ isActive }: any) => (
        "nav__link " + (isActive ? " active-link" : "")
    );

    const pages = [
        {
            link: `${peerfolioKey}`,
            icon: "bx-table",
            label: "Dashboard",
        },
        {
            link: `${peerfolioKey}/analytics`,
            icon: "bx-line-chart",
            label: "Analytics",
        },
        {
            link: `${peerfolioKey}/peers`,
            icon: "bxs-user-account",
            label: "Peers",
        },
        {
            link: `${peerfolioKey}/settings`,
            icon: "bx-cog",
            label: "Settings",
        },
    ];

    return (
        <nav>
            <div className="nav__menu" id="nav-menu">
                <ul className="nav__list">
                    {pages.map((page: any) =>
                        <li className="nav__item" key={page.label}>
                            <NavLink to={page.link} end className={getNavLinkClasses} preventScrollReset={true}>
                                <i className={"bx nav__icon " + page.icon}></i>
                                <span className="nav__name">{page.label}</span>
                            </NavLink>
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    )
}
