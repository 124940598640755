import React from 'react';
import { DebounceInput } from 'react-debounce-input';

import './Filter.css';
import usePeerfolioStore from "common/Store";
import useDepotData from 'depot/useDepotData';

export default function Filter() {
    const depotData = useDepotData();
    const [filterTerm, setFilterTerm]: any = usePeerfolioStore(
        (state: any) => [state.filterTerm, state.setFilterTerm]
    );

    return (
        <div className="depot_filter">
            <div className="filter_outer_wrapper">
                <div className="filter_inner_wrapper">
                    <DebounceInput
                        debounceTimeout={300}
                        maxLength={30}
                        type="text"
                        placeholder="Filter..."
                        value={filterTerm}
                        onChange={event => setFilterTerm(event.target.value)}
                    />
                    <div className="filterResult" data-match={depotData.stocks.length} data-total={depotData.raw.stocks.length} />
                </div>
            </div>
        </div>
    );
}
