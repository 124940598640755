/* eslint @typescript-eslint/no-use-before-define: ["off", { "allowNamedExports": true }] */
/* eslint react-hooks/exhaustive-deps: off */
import { useState } from "react";

import { formatNumber, getPnlTable } from 'common/Utils';
import useDepotData, { getApiUrl } from 'depot/useDepotData';

export {
    useHistoryData as default,
    getFilteredDataTable,
}

function useHistoryData(filterStockId: number) {
    const depotData = useDepotData();
    const [historyData, setHistoryData]: any = useState({});

    if (!historyData[filterStockId]) {
        historyData[filterStockId] = { status: "LOADING" };
        setHistoryData({ ...historyData });
        loadData(depotData.key, filterStockId).then(data => {
            historyData[filterStockId] = data;
            setHistoryData({ ...historyData });
        });
    }

    return historyData[filterStockId];
}

async function loadData(peerfolioKey: string, filterStockId: any) {
    const apiUrl = getApiUrl(
        "history",
        peerfolioKey,
        { filterStockId: filterStockId, cacheInvalidator: /*2h=*/ ~~(Date.now() / 1000 / 60 / 120) }
    );
    return fetch(apiUrl, { cache: "force-cache" }).then(response => response.json());
}

function getFilteredDataTable(historyData?: any, filterDuration?: number, filterType?: string, filterShowTrades?: boolean, filterStockId?: string) {
    if (!historyData?.transactions) {
        return null;
    }

    var historyDataTable = getDataTable(historyData, filterDuration, filterType);
    historyDataTable = changeResolution(historyDataTable, filterDuration);
    historyDataTable = addTransactions(historyData, historyDataTable, filterShowTrades, filterStockId);

    return historyDataTable;
}

function getDataTable(historyData: any, filterDuration?: number, filterType?: string) {
    const historyDataTableTemplate = {
        cols: [
            { type: "date", label: "Date", },
            { type: "string", role: "tooltip", p: { "html": true }, },
            { type: "number", label: "GreyArea", },
            { type: 'number', label: "BuyPoints", },
            { type: 'string', role: "style", },
            { type: "number", label: "GreenArea", },
            { type: "number", label: "RedArea", },
            { type: "number", label: "AbsLine", },
            { type: "string", role: "style", label: "AbsLineStyle", },
            { type: "number", label: "PercLine", },
            { type: "string", role: "style", label: "PercLineStyle", },
        ],
    };
    const dataTable = new window.google.visualization.DataTable(historyDataTableTemplate);

    if (!historyData || !historyData?.transactions || filterDuration === undefined || filterType === undefined) {
        return dataTable;
    }

    var isLastSignPositive: any = null;
    var firstDiff: any = null;

    var minDate: any = null;
    var maxDate: any = null;
    if (filterDuration > 2000 && filterDuration < 2100) {     // a year
        minDate = new Date(filterDuration, 0, 1);
        maxDate = new Date(filterDuration, 11, 31, 23, 59, 59);
    } else {                                      // # of days
        minDate = new Date();
        minDate.setDate((new Date()).getDate() - filterDuration);
    }

    const rows: any = [];
    Object.entries(historyData.rows).forEach((entry) => {
        const [dateString, row] = entry;
        var date = new Date(dateString);
        // date filter
        if ((date < minDate) || (maxDate && date > maxDate)) {
            return;
        }

        // date.setHours(0, -date.getTimezoneOffset(), 0, 0);  //removing the timezone offset
        // serverData[filterStockId].rows[dateString][0]
        const [value, diff, count]: any = row;
        if (!(filterType === "abs") && firstDiff === null && value) {
            firstDiff = diff;
        }

        const buyValue = (value - diff);
        const absLine = filterType === "abs" ? value : diff - firstDiff;
        const percLine = filterType === "abs" ? diff / buyValue : absLine / (value - absLine);
        const isSignsChanging = isLastSignPositive === null || Math.abs(percLine) < 0.01 ? true : (isLastSignPositive ? (absLine <= 0) : (absLine >= 0));
        isLastSignPositive = (absLine > 0);

        const rowData = {
            "date": date,
            "tooltip": getPnlTable({
                headline: date.toLocaleString(undefined, { weekday: "short" }) + ", " + date.toLocaleString(),
                valueCurrency: "EUR",
                valueStart: (filterType === "abs" ? buyValue : value - absLine),
                // commentStart : (filterTypeAbsolute ? "" : minDate.toLocaleDateString()),
                valueEnd: value,
                count: (count ? count : null),
                priceStart: (count ? buyValue / count : null),
            }),
            "gray": (filterType === "abs" ? (value - Math.max(diff, 0)) : 0),
            "transaction": null,
            "transactionFormating": null,
            "green": (filterType === "abs" ? Math.max(diff, 0) : Math.max(absLine, 0)),
            "red": (filterType === "abs" ? Math.abs(Math.min(diff, 0)) : Math.min(absLine, 0)),
            "absLine": absLine,
            "absLineStyle": "color:" + (isSignsChanging ? "#CCC" : (percLine < 0 ? "#FFCCCC" : "#99AA99")),
            "percLine": percLine,
            "percLineStyle": "color:" + (isSignsChanging ? "#888" : (percLine < 0 ? "red" : "green")),
        };

        rows.push(Object.values(rowData));
    });
    dataTable.addRows(rows);

    return dataTable;
}

function changeResolution(dataTable?: any, filterDuration?: number) {
    if (filterDuration === undefined) {
        return dataTable;
    }

    const unixTimeWeekOffset = 24 * 60 * 60 * 4; // start on MONDAY, 5Jan1970
    const obj: any = { "7": 1, "30": 8, "10000": 48 };
    const resolutionInHours = obj[filterDuration.toString()] || 24;

    const filter = {
        column: 0,
        test: (value: any, rowId: any, columnId: any, datatable: any) => {
            if (!rowId || rowId === datatable.getNumberOfRows() - 1) {
                return true; // keep first and last row
            }
            const nextValue = datatable.getValue(rowId + 1, columnId);
            const bucketThisRow = (value.getTime() / 1000 - unixTimeWeekOffset) / (60 * 60 * resolutionInHours);
            const bucketNextRow = (nextValue.getTime() / 1000 - unixTimeWeekOffset) / (60 * 60 * resolutionInHours);
            return Math.floor(bucketThisRow) !== Math.floor(bucketNextRow);
        },
    };

    // todo: remove dataview by just deleting rows from table
    const dataView = new window.google.visualization.DataView(dataTable);
    const visibleRowIds = dataTable.getFilteredRows([filter]);
    dataView.setRows(visibleRowIds);
    return dataView.toDataTable();
}

function addTransactions(historyData?: any, filteredTable?: any, filterShowTrades?: boolean, filterStockId?: string) {
    if (!filterShowTrades) {
        return filteredTable;
    }

    for (var i = 0; i < filteredTable.getNumberOfRows(); i++) {
        const maxDate: Date = filteredTable.getValue(i, 0);
        const minDate: Date = (i ? filteredTable.getValue(i - 1, 0) : new Date(maxDate.getTime() - 1000));
        var transactionTooltip = "";
        var hasTrades: boolean = false;
        var hasSales: boolean = false;

        for(const dateStr of Object.keys(historyData.transactions)) {
            const transactionDate: Date = new Date(dateStr);
            if (transactionDate > minDate && transactionDate <= maxDate) {
                hasTrades = true;
                for(const transaction of historyData.transactions[dateStr]) {
                    if (transaction.count < 0) {
                        // todo: add with or without profit
                        hasSales = true;
                    }

                    const stockName = historyData.stockFilterAvailable[transaction.id];
                    transactionTooltip += `
                            <tr style="${transaction.count < 0 ? "font-weight: bold; color: red" : ""}">
                            ${filterStockId ? "" : `<td>${stockName ? stockName.substr(0, 15) : transaction.id}: </td>`}
                            ${transaction.buyValue ?
                            `<td style="text-align: right">${formatNumber(transaction.count, undefined, undefined, true)}</td>
                                <td>x</td>
                                <td style="text-align: right">${formatNumber(transaction.buyValue / Math.abs(transaction.count), "EUR", 2)}</td>
                                <td>=</td>
                                <td style="text-align: right">${formatNumber(transaction.buyValue, "EUR")}</td>`
                            :
                            `<td>${transaction.count > 0 ? "buy" : "sell"}</td>`
                        }</tr>`;
                }
            }
        };

        if (hasTrades) {
            filteredTable.setValue(i, 3, 1);
            if (hasSales) {
                filteredTable.setValue(i, 4, 'point {size: 7; fill-color: #a52714; shape-type: triangle; rotation: 180;}');
            } else {
                filteredTable.setValue(i, 4, 'point {size: 4; fill-color: #666; shape-type: square; rotation: 180;}');
            }
        }

        if (transactionTooltip) {
            filteredTable.setValue(i, 1, filteredTable.getValue(i, 1) + "<table>" + transactionTooltip + "</table>");
        }
    }

    return filteredTable;
}
