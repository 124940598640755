import React from "react";

import useDepotData from 'depot/useDepotData';

export default function SettingsPage() {
    const comdirectOpen: string = "http://www.comdirect.de/inf/musterdepot/pmd/freunde.html?SORT=PROFIT_LOSS_POTENTIAL_CURRENCY_PORTFOLIO_PCT&SORTDIR=ASCENDING&portfolio_key=";
    const comdirectEdit: string = "https://nutzer.comdirect.de/inf/musterdepot/pmd/meineuebersicht.html?name=";
    const depotData = useDepotData();
    
    return (
        <div>
            <h2>Settings (to be finsihed)</h2>
            {depotData && depotData.type === "comdirect" ?
                <div>
                    <br /> 
                    <b>Comdirect Depot</b><br /> 
                    <a href={comdirectOpen + depotData.key} target="_blank" rel="noreferrer">Open</a>
                    |
                    <a href={comdirectEdit + depotData.title} target="_blank" rel="noreferrer">Edit</a>
                </div>
                :
                <></>
            }
        </div>
    )
}
