/*eslint @typescript-eslint/no-use-before-define: ["error", { "allowNamedExports": true }]*/
export {
    getColorForNumber,
    formatDuration,
    isSameDay,
    formatNumber,
    ucwords,
    getPnlTable,
};

function getColorForNumber(numberBetweenMinusAndPlus100: number): string {
    const minColor = 80;
    const maxColor = 230;
    const number = numberBetweenMinusAndPlus100 * (maxColor - minColor) / 100;
    const red = Math.round(Math.min(maxColor, minColor - Math.min(number, 0)));
    const green = Math.round(Math.min(maxColor, minColor + Math.max(number, 0)));
    return `rgb(${red},${green},${minColor})`;
}

function formatDuration(durationInSecond: number): string {
    if (!durationInSecond) {
        return "";
    }

    var diffsFormated: any = [];
    const diffs: any = {
        y: 60 * 60 * 24 * 365,
        w: 60 * 60 * 24 * 7,
        d: 60 * 60 * 24,
        h: 60 * 60,
        m: 60,
        s: 1,
    };

    Object.entries(diffs).forEach(([key, secs]: any) => {
        if (secs < durationInSecond) {
            diffsFormated.push(Math.floor(durationInSecond / secs) + key);
            durationInSecond = durationInSecond % secs;
        }
    });

    return diffsFormated.slice(0, 2).join(" ");
}

function isSameDay(date1: Date, date2: Date): boolean {
    return (
        date1 && date2
        && date1.getFullYear() === date2.getFullYear()
        && date1.getMonth() === date2.getMonth()
        && date1.getDate() === date2.getDate()
    );
}

function formatNumber(number: number, type?: "%" | "EUR" | "DOL" | "USD", decimals?: number, includeSign?: boolean): string {
    if (!number) {
        number = 0;
        decimals = decimals || 0;
    }

    /*
    * without decimals defined:
    * - 100.123  -> 100
    * - 10.123   -> 10
    * - 1.123    -> 1.1
    * - 0.123    -> 0.12
    */
    if (decimals === null || typeof decimals === "undefined") {
        decimals = Math.ceil(Math.max(0, 1 - Math.log10(Math.abs(number))));
    }
    decimals = Math.min(decimals, 2);

    var suffix = "";
    if (type === "%" && number > 9999) { // fix 10000% crazyness
        number = Math.round(number) / 1000;
        suffix = "k";
    }

    const {language, userLanguage} : any = window.navigator;
    const formatter = new Intl.NumberFormat(
        (language || userLanguage),
        {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        }
    );

    return (includeSign && number > 0 ? "+" : "") +
        formatter.format(number) + suffix +
        (type === "%" ? "%" : "") +
        (type === "EUR" ? "€" : "") +
        (type === "DOL" || type === "USD" ? "$" : "");
}

function getPnlTable(options: any): string {
    const diff = options.valueEnd - options.valueStart;
    const diffPercent = diff / options.valueStart * 100;

    return `
    <table class="pnlTable">
        ${options.headline ? "<tr><th colspan='3'>" + options.headline + "</th></tr>" : ""}
        <tr>
            <td></td>
            <td>${formatNumber(options.valueStart, options.valueCurrency, 0)}</td>
            <td class="price">
                ${options.commentStart ? options.commentStart : ""}
                ${options.priceStart ?
            "("
            + (options.count ? "=" + formatNumber(options.count) + " x " : "")
            + formatNumber(options.priceStart, options.priceCurrency, 2)
            + ")" : ""}
            </td>
        </tr>
        <tr style="color: ${getColorForNumber(diffPercent > 0 ? 70 : -70)}">
            <td>${diffPercent < 0 ? "-" : "+"}</td>
            <td>${formatNumber(Math.abs(diff), options.valueCurrency, 0)}</td>
            <td class="diff">(${formatNumber(diffPercent, "%", undefined, true)})</td>
        </tr>
        <tr>
            <td class="total" colspan="2">${formatNumber(options.valueEnd, options.valueCurrency, 0)}</td>
            <td class="price">
                ${options.priceEnd ? "(" + formatNumber(options.priceEnd, options.priceCurrency, 2) + ")" : ""}
            </td>
        </tr>
    </table>`;
}

function ucwords(str: string): string {
    const toUpperCaseFn = (s: string) => s.toUpperCase();

    str = str
        .toLowerCase()
        .replace(/(^\w{1})|([\s']+\w{1})/g, toUpperCaseFn)
        .replace(/([^a-z]{1}[a-z]{1}[^a-z]{1})/ig, toUpperCaseFn); // eg the "P" in S&P500
    str = str.replace(/(\s{1}|^)(esg|etf|msci|us)(\s|$)/ig, function ($1) { return $1.toUpperCase(); });
    return str;
}
