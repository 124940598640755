/*eslint react-hooks/exhaustive-deps: off */
import React, { useState, useRef } from "react";
import { useDarkMode } from 'usehooks-ts';

import ChartControl from 'depot/components/ChartControl';
import { LineChart } from 'depot/components/Chart';
import useQuotesData from 'depot/useQuotesData';
import useDepotData from 'depot/useDepotData';

export default function DevelopmentChart() {
    console.log("DevelopmentChart");

    const chart: any = useRef();
    const depotData = useDepotData();
    const quotesData = useQuotesData();
    const [filterDuration, setFilterDuration]: any = useState(90);
    const updateFilterDuration = (clickInfo: any) => setFilterDuration(clickInfo.id);
    const { isDarkMode } = useDarkMode();

    refreshChart(chart, quotesData, filterDuration, isDarkMode);

    return (depotData.stocks.length ?
        <div>
            <ChartControl items={getControlItems(updateFilterDuration)} />
            <LineChart ref={chart} />
        </div>
        :
        <></>
    );

    // FUNCTIONS //////////////////////////////////////////////////////////////////
    function refreshChart(chart: any, quotesData: any, filterDuration: number, isDarkMode: boolean) {
        if (chart?.draw && quotesData?.stocks) {
            chart.draw(
                quotesData.getFilteredDataTable(quotesData, filterDuration),
                getChartConfig(isDarkMode)
            );
        }
    };

    function getControlItems(onChangeHandler: any) {
        return [
            { id: "30", label: "1m", onChange: onChangeHandler, },
            { id: "90", label: "3m", isActive: true, onChange: onChangeHandler, },
            { id: "365", label: "1y", onChange: onChangeHandler, },
            { id: "1825", label: "5y", onChange: onChangeHandler, },
        ];
    }

    function getChartConfig(isDarkMode: boolean) {
        const devChartOptions: any = {
            width: "100%",
            height: 500,
            chartArea: {
                top: 10,
                bottom: 25,
                left: 15,
                right: 80,
            },
            focusTarget: "category",
            backgroundColor: "transparent",
            legend: {
                position: "none",
            },
            annotations: {
                textStyle: {
                    fontSize: 8,
                },
                datum: {
                    stem: {
                        length: 0,
                    },
                },
            },
            series: {
                0: { targetAxisIndex: 1, },
                1: { targetAxisIndex: 1, },
                2: { targetAxisIndex: 1, },
                3: { targetAxisIndex: 1, },
                4: { targetAxisIndex: 1, },
                5: { targetAxisIndex: 1, },
                6: { targetAxisIndex: 1, },
                7: { targetAxisIndex: 1, },
                8: { targetAxisIndex: 1, },
                9: { targetAxisIndex: 1, },
                10: { targetAxisIndex: 1, },
            },
            vAxes: {
                1: {
                    format: "percent",
                },
            },
            tooltip: {
                isHtml: true,
            },
            interpolateNulls: true,
        };

        if (isDarkMode) {
            devChartOptions.hAxis = { textStyle: { color: "#DDD" } };
            devChartOptions.vAxis = devChartOptions.hAxis;
            devChartOptions.annotations.textStyle.color = "#DDD";
            devChartOptions.series[2].dataOpacity = 0.3;
        }

        return devChartOptions;
    }
}
