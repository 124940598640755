import React from "react";
import { useParams } from 'react-router-dom';

import useVisitedKeyStorage from 'social/useVisitedKeyStorage';

export default function SocialPage() {
    const { peerfolioKey } = useParams();
    const visitedKeys = useVisitedKeyStorage( );
    
    return (
        <div>
            <h2>Peers (to be finsihed)</h2>
            <br /> 
            <ul>
                {
                    visitedKeys.map((visitedKey: any) => (
                        <li key={visitedKey.key}>
                            <a href={"/" + visitedKey.key + "/peers"} style={{ fontWeight: (peerfolioKey === visitedKey.key ? "bold" : "normal") }}>
                                {visitedKey.title}
                            </a>
                            <br /><br />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}
