import React from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useWindowSize } from 'usehooks-ts';

import './App.css';
import AppLayout from 'app/AppLayout';
import DepotPage from 'depot/DepotPage';
import SocialPage from 'social/SocialPage';
import SettingsPage from 'settings/SettingsPage';
import NotFoundPage from 'app/NotFoundPage';

export default function App() {
    console.log("App");

    useWindowSize();

    const reactRouter = createBrowserRouter([{
        element: <AppLayout />,
        children: [
            { path: ":peerfolioKey", element: <DepotPage pageId="home" />, },
            { path: ":peerfolioKey/analytics", element: <DepotPage pageId="analytics" />, },
            { path: ":peerfolioKey/peers", element: <SocialPage />, },
            { path: ":peerfolioKey/settings", element: <SettingsPage />, },
            { path: "*", element: <NotFoundPage />, },
        ],
    }]);

    return <RouterProvider router={reactRouter} />
}
