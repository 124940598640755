/*eslint @typescript-eslint/no-use-before-define: ["error", { "functions": false, "allowNamedExports": true }]*/
/*eslint no-useless-escape: off */
import { useEffect } from 'react';

import { isSameDay, ucwords } from 'common/Utils';
import usePeerfolioStore from 'common/Store';

export {
    useDepotData as default,
    getApiUrl,
    sanitizeName,
}

function useDepotData(peerfolioKey?: any) {
    const [depotData, setDepotData, filterTerm] = usePeerfolioStore(
        (state: any) => [state.depotData, state.setDepotData, state.filterTerm],
    );

    useEffect(() => {
        if (peerfolioKey && !depotData) {
            console.log("Data", "loadDepotData", peerfolioKey);
            setDepotData({ key: peerfolioKey, });
            loadDepotData(peerfolioKey).then(setDepotData);
        }
        
        console.log("Data", "useEffect", "filterTerm:" + filterTerm);
        const newDepotData = filterDepot(depotData, filterTerm);
        setDepotData(newDepotData);
    }, [filterTerm, depotData, setDepotData]);

    return depotData;
}



async function loadDepotData(portfolioKey: string) {
    if (!portfolioKey) {
        const errorDepotData = { errors: ["Peerfol.io key missing"] };
        return errorDepotData;
    }

    /*
    TODO: Make app aware of loading
    setDepot({
        key = ...
    })
    */

    const apiUrl = getApiUrl("stocks", portfolioKey, { cacheInvalidator: /*1min=*/ ~~(Date.now() / 1000 / 60) });

    return fetch(apiUrl, { cache: "force-cache" }).then(response => response.json())
        .then(data => {
            data = sanatizeDepotData(data);
            data.raw = Object.assign({}, data);
            return data;
        });
};


/*
function getPortfolioKey () {
    const url: URL = new URL(window.location.toString());
    const portfolioKey: string = url.searchParams.get("portfolio_key")?.toString() || "";
    const portfolioKeyMatches = window.location.pathname.match(/\/([\w_-]+)/i);
    console.log (portfolioKeyMatches);
    if (portfolioKeyMatches) {
        portfolioKey = portfolioKeyMatches[1];
    }

    console.log (portfolioKey);
    return portfolioKey;
};
*/

function filterDepot(depotData: any, filterTerm: string): any {
    if (!depotData) {
        return depotData;
    }

    depotData.stocks = [...depotData.raw.stocks]; // copy

    if (filterTerm) {
        const keywords: any = filterTerm.toLowerCase().match(/(\-?\"(.*?)\"|(\S+))/gm);
        depotData.stocks = depotData.stocks.filter((stock: any): boolean => {
            const stockJSON = JSON.stringify(stock).toLowerCase();
            const isStockMatching: boolean = keywords.reduce((prevResult: any, keyword: any) => {
                const isPositive = (keyword.at(0) !== "-");
                const trimmedKeyword = keyword.replace(/^\-*\"*(.+)\"*$/, "$1"); // trim  -"..."
                return prevResult && stockJSON.includes(trimmedKeyword) === isPositive;
            }, true);

            return isStockMatching;
        });
    }

    sanatizeDepotData(depotData);

    return depotData;
}

function sanatizeDepotData(depotData: any) {
    if (!depotData || !depotData.title) {
        if (!depotData) {
            depotData = {};
        }
        if (!depotData.errors || depotData.errors.length === 0) {
            depotData.errors = ["Unknown loading error"];
        }
        return depotData;
    }

    // TODO: change data format in Backend
    if (depotData.sharedKey) {
        depotData.key = depotData.sharedKey;
        delete depotData.sharedKey;
    }
    
    if (depotData.key.startsWith("s")) {
        depotData.isShared = true;
    }

    const today = (new Date());
    depotData.value = 0;
    depotData.diffAbs = 0;
    depotData.valueDiffAbsToday = 0;

    // TODO: change data format in Backend
    depotData.stocks = Object.values(depotData.stocks);

    depotData.stocks.forEach((stock: any) => {
        stock.name = sanitizeName(stock.name);

        if (depotData.date && !depotData.date.getMonth) {
            depotData.date = new Date(depotData.date);
        }

        const standardTypes = ["Currency", "Crypto", "Commodity", "Index"];
        if (standardTypes.includes(stock.type)) {
            stock.name = "*" + stock.name;
        }

        if (!stock.errors) {
            stock.errors = [];
        }

        if (!stock.type) {
            stock.type = "-";
        }

        if (!stock.market) {
            stock.market = "-";
        }

        stock.priceDiffPer = Math.round(
            stock.priceDiffAbs / (stock.price - stock.priceDiffAbs) * 10000) / 100;

        if (stock.limitTop && stock.price > stock.limitTop) {
            stock.isAboveLimit = true;
        }
        if (stock.limitBottom && stock.price < stock.limitBottom) {
            stock.isBelowLimit = true;
        }

        stock.note = stock.note || "";

        const capitalLetterTags = stock.note.match(/\b[A-Z][A-Z0-9]{2,}\b/g);
        stock.category = "UNDEFINED";
        if (capitalLetterTags && capitalLetterTags.length > 0) {
            stock.category = capitalLetterTags[0];
        }

        if (stock.date) {
            if (!stock.date.getMonth) {
                stock.date = new Date(stock.date);
            }
            stock.isDataFromToday = isSameDay(today, stock.date);
            stock.dateAge = (today.getTime() - stock.date.getTime()) / 1000;
            stock.isDataOld = (stock.dateAge > 1800);

            if (!depotData.date
                || !(depotData.date instanceof Date)
                || depotData.date < stock.date) {
                depotData.date = stock.date;
            }
        }

        if (stock.count) {
            if (stock.count < 0) {
                stock.errors.push("Quantity below 0");
            }

            depotData.diffAbs += stock.value - stock.buyValue;

            depotData.value += stock.value;
            if (stock.isDataFromToday) {
                depotData.valueDiffAbsToday += stock.valueDiffAbsToday;
            }

            stock.buyDate = new Date(stock.buyDate);
            stock.buyDateAge = (today.getTime() - stock.buyDate.getTime()) / 1000;

            stock.valueDiffAbs = stock.value - stock.buyValue;
            stock.valueDiffPer =
                Math.round(stock.valueDiffAbs / stock.buyValue * 10000) / 100;
        } else {
            stock.count = 0;
            stock.buyPrice = 0;
            stock.buyValue = 0;
            stock.valueDiffAbsToday = 0;
            stock.buyDate = null;
            stock.valueDiffAbs = 0;
            stock.valueDiffPer = 0;
            stock.value = 0;
        }
    });

    depotData.buyValue = depotData.value - depotData.diffAbs;

    if (depotData.date) {
        depotData.isDataFromToday = today.getFullYear() === depotData.date.getFullYear()
            && today.getMonth() === depotData.date.getMonth()
            && today.getDate() === depotData.date.getDate();
            depotData.dateAge = (today.getTime() - depotData.date.getTime()) / 1000;
        depotData.isDataOld = (depotData.dateAge > 1800);
    }

    return depotData;
}

function getApiUrl(actionName: string, portfolioKey?: string, params?: any) {
    params = params || {};
    params.portfolio_key = portfolioKey;
    return `https://api.peerfol.io/api/v1/${actionName}?` + new URLSearchParams(params);
}

function sanitizeName(name: string) {
    name = " " + name + " ";

    // remove token
    name = name.replace(/( AB O\.E\. \d*|\(.*\)|Austauschkurs|Ucits Etf |&#8203;)*/ig, "");

    // cut away crap in names (starting from things like AG to the end) 
    const regExMatchingGroups = [
        " *-*ag ",
        " \d{1}\w{1}/",
        "Namens-Aktien O\.N\.",
        " Oyj",
        " Vz",
        "plc",
        "inc\.",
        " kgaa ",
        "Reg\.",
        "Fund ",
        " corp",
        " Com",
        ",",
        "act\.",
        "reg\.",
        "1C",
        "N\.v\.",
        " - LC",
        " - Ld",
        "inhaber",
        "Pref\. ADR",
        " ADR",
        " kgag ",
        "INH O\.*N\.*",
        " se ",
        " SP\.",
        " sk",
        "B\.v\.",
        "Eo",
        " EO-,",
        " Cl\.",
        " Tech",
        " Dr ",
        " *-* \.{3} ACC",
        "USD DIS",
        "Registered Shares",
        "o\.N\.",
        " Group",
        " Holding",
        "GmbH",
        " co ",
        " cp ",
        "co\.",
        " &#39;",
        " - . ",
        " A ",
        "'A'",
    ];
    const regEx = new RegExp("(" + regExMatchingGroups.join('|') + ").*$", "ig");
    name = name.replace(regEx, "");

    // make first letter of all words (text with leading space) big if only in big or only in small letters - so don't do with eg: ProSiebenMedia AG
    name = name.replace(/[- *]+$/ig, "");
    name = ucwords(name.trim());
    name = name.replace(/\s{1}(esg|etf|msci|us)(\s|$)/ig, function ($1) { return $1.toUpperCase(); });
    name = name.replace(/(.*) Indikation/ig, "$1");
    name = name.replace(/Euro \/ Euro/ig, "Euro");

    return name;
}
