/*eslint @typescript-eslint/no-use-before-define: ["error", { "functions": false, "allowNamedExports": true }]*/
import React, { forwardRef } from "react";
import { useEffectOnce } from 'usehooks-ts';

export {
    LineChart,
    ComboChart,
    BarChart,
    TreeMapChart,
}

const BaseChart = forwardRef((props: any, chartRef: any) => {
    useEffectOnce(() => {
        const googleVisualizationClass: any = window.google.visualization[props.type];
        chartRef.googleChart = new googleVisualizationClass(chartRef.current);
        chartRef.draw = (...args : any) => chartRef.googleChart.draw(...args);
    });

    return <div ref={chartRef}></div>
});

const LineChart     = forwardRef((_props: any, ref: any) => <BaseChart ref={ref} type="LineChart" />);
const ComboChart    = forwardRef((_props: any, ref: any) => <BaseChart ref={ref} type="ComboChart" />);
const BarChart      = forwardRef((_props: any, ref: any) => <BaseChart ref={ref} type="BarChart" />);
const TreeMapChart  = forwardRef((_props: any, ref: any) => <BaseChart ref={ref} type="TreeMap" />);
