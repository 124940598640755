/*eslint react-hooks/exhaustive-deps: off */
import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";

import "./DepotTable.css";
import { formatNumber, getColorForNumber, ucwords } from "common/Utils";
import useDepotData from 'depot/useDepotData';
import StockDetails from "depot/components/StockDetails";

export default function DepotTable() {
    const [stockDetails, setStockDetails]: any = useState(null);
    const depotData = useDepotData();

    const tableConfig: any = React.useMemo(() => getTableConfig(depotData), [depotData.stocks]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable(tableConfig, useSortBy);

    if (!depotData?.stocks.length) {
        return <></>
    }

    return (
        <div>
            <table className="depot " {...getTableProps()}>

                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps([
                                    column.getSortByToggleProps(),
                                    {
                                        className: column.id +
                                            (column.isSorted ? " sortcolumn" : "") +
                                            (column.isSortedDesc ? " isSortedDesc" : ""),
                                    }
                                ])}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>

                <tbody {...getTableBodyProps()}>
                    {rows.map((row: any) => {
                        prepareRow(row)
                        if (!row?.values?.hidden) {
                            return (
                                <tr {...row.getRowProps([{
                                    className: (row?.values?.count ? "" : "watchlist"),
                                    onClick: () => {
                                        setStockDetails({ ...row.original });
                                    },
                                }])}>
                                    {row.cells.map((cell: any) => (
                                        <td {...cell.getCellProps([{
                                            className: (cell.column.id + " " +
                                                (cell.column.isSorted ? " sortcolumn" : "") +
                                                (cell.column.getClassName ? cell.column.getClassName(cell) : "")
                                            ),
                                            title: (cell.column.getCellTitle ? cell.column.getCellTitle(cell) : ""),
                                            style: (cell.column.getCellStyle ? cell.column.getCellStyle(cell) : {}),
                                        },
                                        ])}>
                                            {cell.render("Cell")}
                                        </td>
                                    )
                                    )}
                                </tr>
                            )
                        }
                        return <></>    // just in case
                    })}
                </tbody>

                <tfoot>
                    {footerGroups.map(footerGroup => (
                        <tr {...footerGroup.getFooterGroupProps()}>
                            {footerGroup.headers.map((column: any) => (
                                <th {...column.getFooterProps([
                                    {
                                        className: (column.id + " " + (column.isSorted ? " sortcolumn" : "")),
                                        style: (column.getFooterStyle ? column.getFooterStyle(column) : {}),
                                    },
                                ])}>
                                    {column.render("Footer")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </tfoot>

            </table>

            <StockDetails stock={stockDetails} setStock={setStockDetails} />
        </div>
    )
}

// FUNCTIONS //////////////////////////////////////////////////////////////////
function getTableConfig(depotData: any) {
    return {
        columns: getColumnConfig(depotData),
        data: depotData.stocks,
        initialState: { sortBy: [{ id: "valueDiffAbs" }] },
        disableSortRemove: true,    //only allow Asc,Desc and disabled unsorted 3rd state
        disableMultiSort: true,
        defaultColumn: {
            sortType: "basic",
        },
        autoResetSortBy: false,
    };
}

function getColumnConfig(depotData: any) {
    return [
        {
            accessor: "iterator",
            Header: " ",
            disableSortBy: true,
        },
        {
            accessor: "name",
            Header: "Name",
        },
        {
            accessor: "note",
            Header: "Note",
            getCellTitle: (cellObj: any): string =>
                (cellObj.row.original.note ? cellObj.row.original.note.replace(/'/gi, "\"") : ""),
        },
        {
            accessor: "type",
            Header: "Type",
            Cell: (obj: any) => ucwords(obj.value),
        },
        {
            accessor: "market",
            Header: "Market",
        },
        {
            accessor: "buyDate",
            Header: "BuyDate",
            Cell: (cellObj: any) => (cellObj.value ? cellObj.value.toISOString().substr(0, 10) : ""),
        },
        {
            accessor: "count",
            Header: "BuyCount",
            Cell: (cellObj: any) => formatNumber(cellObj.value),
        },
        {
            accessor: "buyPrice",
            Header: "BuyPrice",
            Cell: (cellObj: any) => formatNumber(cellObj.value, cellObj.row.original.currency, 2),
        },
        {
            accessor: "buyValue",
            Header: "BuyValue",
            Cell: (cellObj: any) => formatNumber(cellObj.value, depotData.currency, 0),
            Footer: (_columnObj: any) => (
                <span className={depotData.isShared ? "shareDepotLabel" : ""}>
                    {formatNumber(depotData.buyValue, depotData.currency, 0)}
                </span>
            ),
        },
        {
            accessor: "limitBottom",
            Header: "Limit",
            getClassName: (cellObj: any): string => (cellObj.row.original.isBelowLimit ? " alert" : ""),
            Cell: (cellObj: any) =>
                (cellObj.value ? formatNumber(cellObj.value, cellObj.row.original.currency, 2) : ""),
        },
        {
            accessor: "limitTop",
            Header: "Limit",
            getClassName: (cellObj: any): string => (cellObj.row.original.isAboveLimit ? " alert" : ""),
            Cell: (cellObj: any) =>
                (cellObj.value ? formatNumber(cellObj.value, cellObj.row.original.currency, 2) : ""),
        },
        {
            accessor: "price",
            Header: "Price",
            Cell: (cellObj: any) => <span>{formatNumber(cellObj.value, cellObj.row.original.currency, 2)}</span>,
        },
        {
            accessor: "priceDiffPer",
            Header: "%",
            Cell: (cellObj: any) => <span>{formatNumber(cellObj.value, "%", 1)}</span>,
            getCellStyle: (cellObj: any): {} => ({
                color: getColorForNumber(cellObj.value * 100),
            }),
            Footer: (_columnObj: any) =>
                formatNumber(depotData.valueDiffAbsToday / (depotData.value - depotData.valueDiffAbsToday) * 100, "%"),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getColorForNumber(depotData.valueDiffAbsToday),
            }),
        },
        {
            accessor: "valueDiffAbsToday",
            Header: "Today",
            Cell: (cellObj: any) => <span>{formatNumber(cellObj.value, depotData.currency)}</span>,
            getCellStyle: (cellObj: any): {} => ({
                color: getColorForNumber(cellObj.row.values.priceDiffPer * 100),
            }),
            Footer: (_columnObj: any) => formatNumber(depotData.valueDiffAbsToday, depotData.currency, 0),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getColorForNumber(depotData.valueDiffAbsToday),
            }),
        },
        {
            accessor: "valueDiffPer",
            Header: "Abs%",
            Cell: (cellObj: any) => formatNumber(cellObj.value, "%"),
            getCellStyle: (cellObj: any): {} => ({
                color: getColorForNumber(cellObj.value),
            }),
            Footer: (_columnObj: any) => formatNumber(depotData.diffAbs / depotData.buyValue * 100, "%"),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getColorForNumber(depotData.diffAbs),
            }),
        },
        {
            accessor: "valueDiffAbs",
            Header: "Abs",
            Cell: (obj: any) => formatNumber(obj.row.values.value - obj.row.values.buyValue, depotData.currency),
            getCellStyle: (obj: any): {} => ({
                color: getColorForNumber((obj.row.values.value - obj.row.values.buyValue) / depotData.value * 10000)
            }),
            Footer: (_columnObj: any) => formatNumber(depotData.diffAbs, depotData.currency, 0),
            getFooterStyle: (_columnObj: any): {} => ({
                color: getColorForNumber(depotData.diffAbs),
            }),
        },
        {
            accessor: "value",
            Header: "Value",
            Cell: (obj: any) => formatNumber(obj.value, depotData.currency),
            Footer: (_columnObj: any) => formatNumber(depotData.value, depotData.currency, 0),
        },
    ];
}
