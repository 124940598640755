import React from 'react';
import { Navigate } from "react-router-dom";

export default function NotFoundPage() {
    const demoPeerfolioKey = "15084834307983542333080021680485269203302201198874537233821434611422112112112";

    if (process.env.NODE_ENV === "development") {
        return <Navigate to={"/" + demoPeerfolioKey} replace={true} />
    }

    return (
        <div>404 - Not found</div>
    );
}
