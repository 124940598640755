import React from 'react';

import useGoogleChartLib from 'depot/useGoogleChartLib';
import DepotTable from 'depot/components/DepotTable';
import ShareChart from 'depot/components/ShareChart';
import PnlChart from 'depot/components/PnlChart';
import HistoryChart from 'depot/components/HistoryChart';
import DevelopmentChart from 'depot/components/DevelopmentChart';
import Filter from "depot/components/Filter";
import useDepotData from 'depot/useDepotData';

export default function DepotPage({ pageId }: any) {
    const isGoogleChartReady = useGoogleChartLib();
    const depotData = useDepotData();

    if (!depotData || !isGoogleChartReady) {
        return <div className='loaderInfo spinner' style={{ height: '100%' }} />
    } else if (depotData.errors.length) {
        return <div>Error</div>
    }

    return (
        <>
            {depotData?.isShared &&
                <div className="shareDepotInfo">
                    <div>
                        This Peerfolio is <b>scaled to 1000&euro;</b> to allow public sharing.
                        The actual total value is different.
                    </div>
                </div>
            }

            {pageId === "home" &&
                <>
                    <Filter />
                    <DepotTable />
                    <ShareChart />
                    <PnlChart />
                    <DevelopmentChart />
                </>
            }

            {pageId === "analytics" &&
                <HistoryChart />
            }
        </>
    )
}
