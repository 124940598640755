/*eslint react-hooks/exhaustive-deps: off */
/*eslint jsx-a11y/anchor-is-valid: off */
import React from "react";

import { formatNumber, formatDuration, getColorForNumber, getPnlTable } from "common/Utils";
import PopUp from "app/PopUp";
import useDepotData from 'depot/useDepotData';

export default function StockDetails({ stock, setStock }: any) {
    const depotData = useDepotData();
    const tooltipStockChartUrl = "https://charts.comdirect.de/charts/rebrush/design_small.ewf.chart?WIDTH=256&HEIGHT=173&TIME_SPAN=[TIME]&TYPE=MOUNTAIN&ID_NOTATION=[ID]";

    if (!stock) {
        return <></>
    }

    return (
        <PopUp closeFn={() => setStock("")}>
            <div className="stockDetails">
                <h2>{stock.name} {stock.symbol ? " (" + stock.symbol + ")" : ""}</h2>

                <p>
                    <span className="price">
                        {formatNumber(stock.price, stock.currency, 2)}
                    </span>
                    <span className="priceDiffAbs" style={{ color: getColorForNumber(stock.priceDiffPer * 100) }}>
                        {formatNumber(stock.priceDiffAbs, stock.currency, 2, true)}
                    </span>
                    {typeof stock.priceDiffPer !== "undefined" ?
                        <span className="priceDiffPer" style={{ color: getColorForNumber(stock.priceDiffPer * 100) }}>
                            {formatNumber(stock.priceDiffPer, "%", undefined, true)}
                        </span>
                        : ""}



                    <br />
                    {stock.limitBottom ?
                        <span className={stock.isBelowLimit ? "alert" : ""}>
                            {formatNumber(stock.limitBottom, stock.currency, 2)} &#60;
                        </span>
                        : ""}

                    {stock.limitBottom || stock.limitTop ? "Limit" : ""}

                    {stock.limitTop ?
                        <span className={stock.isAboveLimit ? "alert" : ""}> &#62;
                            {formatNumber(stock.limitTop, stock.currency, 2)}
                        </span>
                        : ""}


                    {stock.currencyMultiplier && stock.currencyMultiplier !== 1 ?
                        <span>
                            <br />1€ = {formatNumber(stock.currencyMultiplier, stock.currency)}
                        </span>
                        : ""}
                </p>

                {stock.count ?
                    <div>
                        <p>Diff Today:
                            <span className="valueDiffAbsToday" style={{ color: getColorForNumber(stock.valueDiffAbsToday) }}>
                                {formatNumber(stock.valueDiffAbsToday, depotData.currency, 2)}
                            </span>
                        </p>
                        <p>
                            {getPnlTable({
                                headline: stock.buyDate.toISOString().substr(0, 10) + " (" + formatDuration(stock.buyDateAge) + ")",
                                valueCurrency: depotData.currency,
                                valueStart: stock.buyValue,
                                valueEnd: stock.value,
                                count: stock.count,
                                priceStart: stock.buyPrice,
                                priceCurrency: stock.currency,
                            })}
                        </p>
                        {stock.errors ? <p className="alertError">{stock.errors.join("<br>")}</p> : ""}
                        {stock.note ? "<p><i>&quot;" + stock.note + "&quot;</i></p>" : ""}
                    </div>
                    : ""}


                <div>
                    {stock.market} | {stock.type}
                    {stock.wkn ? " | " + stock.wkn : ""}
                    {stock.isin ? " | " + stock.isin : ""}
                    | age {formatDuration(stock.dateAge)}
                    <br />
                </div>

                <div>
                    {stock.notationId ?
                        <a href="https://www.comdirect.de/inf/aktien/detail/uebersicht.html?ID_NOTATION={stock.notationId}"
                            target="_blank" rel="noreferrer">
                            <img src="https://lh3.ggpht.com/oDdHm6AlrMpjCIazyHQVzeEIcH28_7RSi7CGTUFz629aV6t0M2nAmHG93ZhSJqifGtw=w32"
                                width="32" alt="comdirect" />
                        </a>
                        : ""}

                    {stock.isin ?
                        <span>
                            <a href="https://aktie.traderfox.com/visualizations/{stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://pbs.twimg.com/profile_images/797361743626465280/eAhqkp1P_400x400.jpg" width="32" alt="traderfox" />
                            </a>
                            <a href="http://markets.businessinsider.com/searchresults?_search={stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://i.insider.com/596e4e7a552be51d008b50fd?width=600&format=jpeg&auto=webp" width="32" alt="businessinsider" />
                            </a>
                            <a href="http://m.ariva.de/search/search.m?searchname={stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://pbs.twimg.com/profile_images/435793734886645760/TmtKTE6Y.png" width="32" alt="ariva" />
                            </a>
                            <a href="https://www.onvista.de/aktien/{stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://s.onvista.de/css-69545/web/portal/nl/layout_img/favicon.png" width="32" alt="onvista" />
                            </a>
                            <a href="http://www.finanzen.net/suchergebnis.asp?_search={stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://images.finanzen.net/images/favicon/favicon-32x32.png" width="32" alt="finanzen.net" />
                            </a>
                            <a href="https://www.consorsbank.de/euroWebDe/-?$part=Home.security-search&$event=search&pattern={stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://www.consorsbank.de/content/dam/de-cb/system/images/evr/favicon.ico" width="32" alt="consorsbank" />
                            </a>
                        </span>
                        : ""}

                    {stock.symbol ?
                        <span>
                            <a href="https://finance.yahoo.com/quote/{stock.symbol}" target="_blank" rel="noreferrer">
                                <img src="https://finance.yahoo.com/favicon.ico" width="32" alt="yahoo" />
                            </a>
                            <a href="https://www.google.com/finance/quote/{stock.symbol}" target="_blank" rel="noreferrer">
                                <img src="https://www.google.de/images/branding/product/ico/googleg_lodp.ico" width="32" alt="google" />
                            </a>
                        </span>
                        : ""}

                    {stock.isin && stock.type === "etf" ?
                        <span>
                            <a href="https://www.justetf.com/de/etf-profile.html?isin={stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://www.justetf.com/images/logo/justetf_icon_m.png" width="32" alt="justetf.com" />
                            </a>
                            <a href="https://de.extraetf.com/etf-profile/{stock.isin}" target="_blank" rel="noreferrer">
                                <img src="https://de.extraetf.com/favicon.ico" width="32" alt="extraetf.com" />
                            </a>
                            <a href="https://www.trackingdifferences.com/ETF/ISIN/{stock.isin}" target="_blank" rel="noreferrer">
                                + "<img src="https://www.trackingdifferences.com/images/favicon-32.png" width="32" alt="trackingdifferences" />
                            </a>
                        </span>
                        : ""}

                    {stock.wkn && stock.type === "stock" ?
                        <a href="http://www.finanznachrichten.de/suche/suchergebnis.asp?words={stock.wkn}" target="_blank" rel="noreferrer">
                            <img src="https://fns1.de/g/fb.png" width="32" alt="finanznachrichten.de" />
                        </a>
                        : ""}


                    <div className="chartContainer" data-stock-notation-id={stock.notationId}>
                        <div className="controls"><a className="active">10d</a> <a>6m</a> <a>1y</a> <a>5y</a> <a>max</a></div>
                        <img src={tooltipStockChartUrl.replace("[TIME]", "10D").replace("[ID]", stock.notationId)} alt="chart" />
                    </div>
                </div>

            </div>
        </PopUp>
    )
}
