import React from "react";
import { useDocumentTitle } from 'usehooks-ts';

import useDepotData from 'depot/useDepotData';

export default function AppTitle() {
    const depotData = useDepotData();
    const title = "peerfol.io" + (depotData?.title ? " | " + depotData.title : "");
    useDocumentTitle(title);

    return (
        <h1>
            {!depotData && <div className="dots">Loading</div>}
            {depotData?.title && depotData.title}
            {depotData?.errors?.length > 0 && depotData.errors.join("|")}
        </h1>
    )
}
